
import { IClient } from '@/interfaces/client';
import { IProject } from '@/interfaces/project';
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import Filters from '@/components/Filters.vue';
import Editable from '@/components/Editable.vue';

export default defineComponent({
    components: { Editable, Filters },
    mixins: [Base],
    data(): any {
        return {
            rendererUrl: process.env.VUE_APP_RENDERER_URL,
            items: {
                data: [] as Array<IProject>,
                meta: {
                    total: 0,
                },
            },
            params: {
                filter: '',
                page: 1,
                sortBy: 'created_at',
                orderBy: 'desc',
                client: undefined as any | IClient,
            },
        };
    },
    mounted(): void {
        this.getItems();
    },
    methods: {
        getItems(page: number = 1): void {
            this.params.page = page;
            this.get('projects', {
                params: {
                    ...this.params,
                    client_id: this.params.client ? this.params.client.id : null,
                },
            }).then(({ data }: any) => {
                this.items.data = data.data;
                this.items.meta = data.meta;
            });
        },
    },
});
